<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
    </div>
    <b-card-code title="Asset Discovery">
      <div class="d-flex justify-content-center mb-1" v-if="loading">
        <b-spinner class="float-right" label="Floated Right" />
      </div>
      <div v-else>
        <b-table
          responsive
          id="atTable"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          v-if="items.length"
          hover
        >
          <template #cell(index)="data">
            {{ data.index + 1 + perPage * (currentPage - 1) }}
          </template>
          <template #cell(name)="data">
            {{ data.item.name }} <br />
            <div v-if="data.item.is_newly_founded && data.item.name != null">
              <b-button
                @click="addToAssets(data.item.id, 'domain')"
                variant="outline-warning"
                style="position: relative"
                :disabled="isDisable"
                size="sm"
              >
                <span v-if="isLoading && data.index === currentIndexDomain"
                  ><b-spinner type="border" small />Please wait</span
                >
                <span v-else>Add to assets</span>
              </b-button>
              <b-button
                variant="outline-warning"
                style="position: relative"
                class="ml-1"
                size="sm"
                @click="addAndScan(data.item.id, 'domain')"
                :disabled="isDisable"
              >
                <span v-if="isLoading && data.index === currentIndexDomain"
                  ><b-spinner type="border" small />Please wait</span
                >
                <span v-else> Add and Scan</span>
              </b-button>
            </div>
            <div
              v-else-if="
                !data.item.domain_added_to_asset && data.item.name != null
              "
            >
              <b-button
                @click="addToAssets(data.item.id, 'domain', data.index)"
                variant="outline-primary"
                style="position: relative"
                :disabled="isDisable"
                size="sm"
              >
                <span v-if="isLoading && data.index === currentIndexDomain"
                  ><b-spinner type="border" small />Please wait</span
                >
                <span v-else>Add to assets</span>
              </b-button>
              <b-button
                variant="outline-primary"
                style="position: relative"
                class="ml-1"
                size="sm"
                @click="addAndScan(data.item.id, 'domain')"
                :disabled="isDisable"
              >
                <span v-if="isLoading && data.index === currentIndexDomain"
                  ><b-spinner type="border" small />Please wait</span
                >
                <span v-else> Add and Scan</span>
              </b-button>
            </div>
            <div
              v-else-if="
                data.item.domain_added_to_asset && data.item.name != null
              "
            >
              <b-button
                variant="outline-success"
                style="
                  position: relative;
                  padding-left: 35px;
                  padding-right: 35px;
                "
                :disabled="!data.item.domain_ref_asset_id"
                size="sm"
                @click="
                  $router.push({
                    path: '/assets/scan/' + data.item.domain_ref_asset_id,
                  })
                "
              >
                Added
              </b-button>
            </div>
          </template>
          <template #cell(ip_address)="data">
            {{ data.item.ip_address }} <br />
            <div
              v-if="data.item.is_newly_founded && data.item.ip_address != null"
            >
              <b-button
                @click="addToAssets(data.item.id, 'ip')"
                variant="outline-warning"
                style="position: relative"
                v-if="
                  data.item.is_newly_founded && data.item.ip_address != null
                "
                :disabled="isDisableIP"
                size="sm"
              >
                <span v-if="isLoadingIP && data.index === currentIndexIP"
                  ><b-spinner type="border" small />Please wait</span
                >
                <span v-else>Add to assets</span>
              </b-button>
              <b-button
                variant="outline-warning"
                style="position: relative"
                class="ml-1"
                size="sm"
                @click="addAndScan(data.item.id, 'ip')"
                :disabled="isDisableIP"
              >
                <span v-if="isDisableIP && data.index === currentIndexIP"
                  ><b-spinner type="border" small />Please wait</span
                >
                <span v-else> Add and Scan</span>
              </b-button>
            </div>
            <div
              v-else-if="
                !data.item.ip_added_to_asset && data.item.ip_address != null
              "
            >
              <b-button
                @click="addToAssets(data.item.id, 'ip')"
                variant="outline-primary"
                style="position: relative"
                :disabled="isDisableIP"
                size="sm"
              >
                <span v-if="isLoadingIP && data.index === currentIndexIP"
                  ><b-spinner type="border" small />Please wait</span
                >
                <span v-else>Add to assets</span>
              </b-button>
              <b-button
                variant="outline-primary"
                style="position: relative"
                class="ml-1"
                size="sm"
                @click="addAndScan(data.item.id, 'ip')"
                :disabled="isDisableIP"
              >
                <span v-if="isDisableIP && data.index === currentIndexIP"
                  ><b-spinner type="border" small />Please wait</span
                >
                <span v-else> Add and Scan</span>
              </b-button>
            </div>
            <div
              v-else-if="
                data.item.ip_added_to_asset && data.item.ip_address != null
              "
            >
              <b-button
                variant="outline-success"
                style="
                  position: relative;
                  padding-left: 35px;
                  padding-right: 35px;
                "
                :disabled="!data.item.ip_ref_asset_id"
                size="sm"
                @click="
                  $router.push({
                    path: '/assets/scan/' + data.item.ip_ref_asset_id,
                  })
                "
              >
                Added
              </b-button>
            </div>
          </template>
        </b-table>
        <div v-else>
          <div
            class="w-100 d-flex flex-column align-items-center justify-content-center py-4"
          >
            <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
            <h3 class="font-weight-bolder">No Data Found</h3>
          </div>
        </div>
      </div>

      <!-- <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        :align="pagination_pos"
        aria-controls="atTable"
      /> -->
      <b-pagination-nav
        v-model="currentPage"
        :numberOfPages="total"
        :total-rows="rows"
        :link-gen="linkGen"
        :per-page="perPage"
        :align="pagination_pos"
        aria-controls="atTable"
        use-router
      />
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BSpinner,
  },
  data() {
    return {
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: "Domain Discovered" },
        { key: "ip_address", label: "IP Address" },
        // { key: "actions", label: "Actions" },
      ],
      loading: false,
      isLoading: false,
      isDisable: false,
      isLoadingIP: false,
      isDisableIP: false,
      type: "",
      currentIndexDomain: Number,
      currentIndexIP: Number,
    };
  },
  props: {
    scan_id: {
      type: Number,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  watch: {
    scan_id: function (old, newV) {
      this.loadAssets();
    },
    currentPage: function (newVal, oldVal) {
      this.loadAssets(false);
    },
  },
  created: function () {
    if (this.scan_id) {
      this.loadAssets();
    }
  },
  methods: {
    //  linkGen: function (pageNum) {
    //   return this.$router.currentRoute.path + "?page=" + pageNum;
    // },
    linkGen: function (pageNum) {
      // Generate links based on page numbers
      // return "#page=" + pageNum;
    },
    loadAssets: function (reset = true) {
      // if (this.$route.query.page) this.currentPage = this.$route.query.page;
      if (reset) this.currentPage = 1;
      // if (!reset && this.$route.query.page) {
      //   this.currentPage = parseInt(this.$route.query.page);
      // }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/discovered-assets?scan_id=" +
          this.scan_id +
          "&page=" +
          this.currentPage,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },
    addToAssets: function (id, type, index) {
      this.type = type;
      this.currentIndexDomain = index;
      this.currentIndexIP = index;
      if (this.type === "domain") {
        this.isDisable = true;
        this.isLoading = true;
      } else {
        this.isDisableIP = true;
        this.isLoadingIP = true;
      }
      let data = {
        type: type,
      };
      // add-to-asset
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/discovered-assets/" +
          id +
          "/add-to-asset/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.loadAssets();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
        this.isDisable = false;
        this.isLoading = false;
        this.isDisableIP = false;
        this.isLoadingIP = false;
      });
    },

    addAndScan: function (id, type, index) {
      this.type = type;
      this.currentIndexDomain = index;
      this.currentIndexIP = index;
      if (this.type === "domain") {
        this.isDisable = true;
        this.isLoading = true;
      } else {
        this.isDisableIP = true;
        this.isLoadingIP = true;
      }
      let data = {
        type: type,
      };
      // add-to-asset
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset-discovery/discovered-assets/" +
          id +
          "/add-asset-and-scan/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.loadAssets();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
        this.isDisable = false;
        this.isLoading = false;
        this.isDisableIP = false;
        this.isLoadingIP = false;
      });
    },
  },
};
</script>